<template>
    <div class="friends bc-wrapper bc-horizontal">
        <div class="wrapper">
            <h1>Welcome Aboard!</h1>
            <p>From stress to success! You have just found the best and most reliable assignment helper platform on the market!</p>
            <div class="friends_info">
                <div class="section how-it-works">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Watch Ally's Welcome Speech
                        </h2>
                    </div>
                    <div class="how-it-works__block">
                        <div class="how-it-works__video">
                            <video
                                width="100%"
                                height="100%"
                                controls
                            >
                                <source
                                    :src="require('@/assets/video/welcome.mp4')"
                                    type="video/mp4"
                                >
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div class="how-it-works__bullets">
                            <ul>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        More than 10 years experience in academic writing
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        Our writers are smart and professional
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        Zero AI generated or plagiarized content
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        Guaranteed delivery on time
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        All subjects and all kinds of assignments
                                    </span>
                                </li>
                                <li>
                                    <Check
                                        height="35px"
                                        width="35px"
                                    />
                                    <span>
                                        24/7 Support
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="button-wrapper">
                    <router-link :to="{ name: 'order' }">
                        <button class="btn-base btn-main button">
                            Create order
                        </button>
                    </router-link>
                </div>

                <WelcomeFaq>
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            FAQ
                        </h2>
                    </div>
                </WelcomeFaq>

                <div class="section sharing-widget">
                    <div class="stripe">
                        <div class="line" />
                        <h2 class="center-text">
                            Share with a friend
                        </h2>
                    </div>
                    <p class="description">
                        You can also share this service with your friends and earn automatic rewards and affiliate commissions on all their purchases.
                    </p>
                    <invite-widget />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InviteWidget from '@/components/friends/InviteWidget.vue';
import Check from 'mdi-vue/Check.vue'

import { mapGetters } from 'vuex'

import WelcomeFaq from '@/components/account/WelcomeFaq.vue';

export default {
    name: 'FriendsAbout',
    components: {
        InviteWidget,
        Check,
        WelcomeFaq
    },
    data() {
        return {
            isMoneyMakerPlan: null
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterAffiliate',
            'getterFriends'
        ])
    },
    created() {
        this.isMoneyMakerPlan = this.getterAffiliate
    }
}
</script>

<style lang="scss">
    .section {
        margin: 80px 0px;
    }

    .white{
        color: #fff;
    }

    .title-percent {
        border: solid 2px;
        padding: 3px;
        border-radius: 5px;
        font-weight: bold;
    }

    .how-it-works {

        margin: 0 !important;

        &__block {
            margin: 0 !important;
            display: flex;
            width: 100%;
            align-items: center;
            gap: 20px;
            @include media1200max {
                flex-direction: column;
                gap: 20px;
            }
        }

        &__bullets {
            align-items: center;
            margin: 15px 0;
            width: 75%;
            @include media1200max {
                width: 100%;
            }
            @include media768max {
                flex-direction: column;
                gap: 20px;
            }
        }

        &__bullets ul li {
            display: flex;
            align-items: baseline;
            margin-bottom: 10px;
            line-height: 30px;
            gap: 10px;
        }

        &__video {
            display: flex;
            justify-content: right;
            align-items: center;
            margin: 15px 0;
            @include media1200max {
                justify-content: center;
            }
        }
    }

    .video-section ul li{
        display: flex;
        align-items: baseline;
        margin-bottom: 10px;
        line-height: 30px;
        gap: 10px;
    }

    .stripe {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-bottom: 50px;
    }
    .line {
        width: 100%;
        height: 1px;
        background-color: #000;
        position: absolute;
    }
    .center-text {
        text-align: center;
        font-size:18px !important;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #EAEBEF;
        padding: 5px 10px;
        z-index: 2;
    }
    .sharing-widget {
        display: flex;
        flex-direction: column;
        .description {
            margin-top: 0px;
            margin-bottom: 50px;
        }
    }

    .button-wrapper {
        width: 100%;
        text-align: center;
        .button {
            font-size: 26px;
            padding: 50px;
            border-radius: 15px;
        }
    }

</style>
