<template>
    <div class="section">
        <slot />
        <div
            v-for="(question, index) in faqFriends"
            :key="index"
            class="faq_info-wrapper"
        >
            <div
                class="faq_info-title"
                @click="showDescription(index)"
            >
                <span
                    :class="{ 'faq_info-title--active' : isDescriptionOpen === index }"
                >
                    {{ question.title }}
                </span>
                <span class="faq_info-chevron">
                    <chevron-up-icon
                        v-if="isDescriptionOpen === index"
                        class="faq_info-chevron&#45;&#45;active"
                    />
                    <chevron-down-icon v-else />
                </span>
            </div>
            <transition name="slide">
                <span
                    v-show="isDescriptionOpen === index"
                    class="faq_info-description"
                >
                    {{ question.description }}
                </span>
            </transition>
        </div>
    </div>
</template>

<script>

import ChevronUpIcon from 'mdi-vue/ChevronUp'
import ChevronDownIcon from 'mdi-vue/ChevronDown'

export default {
    name: 'AffiliateFaq',
    components: {
        ChevronUpIcon,
        ChevronDownIcon
    },
    data() {
        return {
            isDescriptionOpen: null
        }
    },
    computed: {
        faqFriends() {
            return [
                {
                    title: 'How do I place my first order?',
                    description: `Click the "Order Now" button, then choose the type of order: Express or Standard. 
                                With a standard order you get full assistance from our managers who will assign 
                                the best writer or your chosen writer. With the Express order type, youAfter that, 
                                fill out the form with all your instructions.`
                },
                {
                    title: 'What is "Standard Order"?',
                    description: `Standard order is our traditional ordering flow, where you create your order with all
                                instructions and pay it. It usually takes only a few minutes. The rest is managed by our team. We assign either your preferred
                                writer or the best available writer.`
                },
                {
                    title: 'What is "Express Order"?',
                    description: `Express order is the new order flow that was recently introduced. It allows you to see our writers in real time, as they bid on your project.
                                Then, you can choose the best writer yourself. It is recommended when you want to make sure that we have an expert who can complete your
                                specific task before paying the order. This will require that you wait for the bid, then choose the writer and then pay the order.`
                },
                {
                    title: 'How do I pay my order?',
                    description: 'We accept all major credit cards, Crypto payments and bank transfer. You can also top up your account with a larger sum and use the balance for quick checkout.'
                },
                {
                    title: 'Do you guarantee to meet the deadline?',
                    description: 'Yes, we do! If we fail to meet the deadline, we will offer a partial refund or even a full refund.'
                },
                {
                    title: 'Do you have a loyalty discount program?',
                    description: 'We have a discount program for return clients. The discount is applied automatically by our system during checkout. It starts at 5% once you reach $500 in spend and progresses to 15% as you go along.'
                },
                {
                    title: 'Is your service absolutely confidential?',
                    description: 'Using our service is totally confidential. We do not disclose information about our clients to any third party.'
                }
            ]
        }
    },
    methods: {
        showDescription(index) {
            this.isDescriptionOpen === index
                ? this.isDescriptionOpen = false
                : this.isDescriptionOpen = index
        }
    }
}

</script>

<style lang="scss" scoped>
.faq {
    &_info {
        margin: 30px 0px;
        line-height: 1.4;
        text-align: left;
        color: #000;
        font-weight: 300;
        word-break: normal;
        &-wrapper {
            margin-bottom: 25px;
        }
        &-title {
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            cursor: pointer;
            font-size: 16px;
            user-select: none;
        }
        &-title--active{
            color: $main-color;
            user-select: none;
        }
        &-description {
            background: white;
            padding: 10px;
            border-radius: 10px;
            max-width: 760px;
            line-height: 1.6;
            text-align: left;
            color: #1f2939;
            font-weight: 300;
            word-break: normal;
            margin: 10px 0;
            display: flex;
        }
        &-chevron {
            cursor: pointer;
        }
        &-chevron--active{
            svg {
                fill: $main-color;
            }
        }
    }
}

</style>
